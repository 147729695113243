import React, { useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import Dialog from "@mui/material/Dialog";
import { Zoom } from "@mui/material";
import { userLogin, getUser } from "@store";
import { PrimaryText, CustomButton, CustomInput } from "@components";
import "../../containerStyle.css";

function LoginModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: "",
      password: "",
      emailError: false,
      passwordError: false,
      logindata: null,
      btnLoading: false,
      isForget: null,
    }
  );

  const apiData = {
    identifier: state.email,
    password: state.password,
  };

  const login = async () => {
    if (state.email && state.password) {
      setState({ btnLoading: true });
      await dispatch(userLogin(apiData)).then((res) => {
        if (res?.payload?.data && res?.payload?.status === 200) {
          dispatch(getUser()).then(() => {
            props.openSuccessModal();
            setState({
              btnLoading: false,
              email: "",
              password: "",
              emailError: false,
              passwordError: false,
              isForget: null,
            });
          });
        } else {
          props.openSuccessModal();
          setState({
            btnLoading: false,
            emailError: false,
            passwordError: false,
            isForget: true,
          });
        }
      });
    } else {
      setState({
        emailError: !state.email,
        passwordError: !state.password,
        isForget: true,
      });
    }
  };

  const register = () => {
    setState({
      email: "",
      password: "",
      emailError: false,
      passwordError: false,
      isForget: null,
    });
    props.closeLoginModal();
  };

  const closeModal = () => {
    props.closeModal();
    setState({
      email: "",
      password: "",
      emailError: false,
      passwordError: false,
      isForget: null,
    });
  };

  const forgetPass = () => {
    props.closeModal();
    setState({
      email: "",
      password: "",
      emailError: false,
      passwordError: false,
      isForget: null,
    });
    navigate("/forgot-password");
  };

  const Title = (e) => {
    return (
      <div className="flex items-center justify-center w-full h-auto mb-4">
        <PrimaryText className="text-2xl text-textColor2 font-bold font-[Cambay] text-center">
          {e.title}
        </PrimaryText>
      </div>
    );
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={() => closeModal()}
      TransitionComponent={Zoom}
      sx={{
        height: "90%",
        marginTop: "3%",
      }}
    >
      <Content>
        <Title title={"LOGIN"} />
        <Line />
        <Container>
          {CustomInput({
            title: "Email",
            value: `${state.email}`,
            onChange: (event) => {
              setState({
                email: event.target.value,
                emailError: false,
                isForget: null,
              });
            },
            maxLength: 40,
            error: state.emailError,
            icon: true,
          })}
          {CustomInput({
            title: "Password",
            value: `${state.password}`,
            onChange: (event) => {
              setState({
                password: event.target.value,
                passwordError: false,
                isForget: null,
              });
            },
            maxLength: 30,
            error: state.passwordError,
            secureText: true,
            icon: true,
          })}
          {state.isForget ? (
            <button
              className="w-fit h-auto flex flex-start mt-0.5 py-1 px-1 text-sm font-[Cambay] hover:underline underline-offset-4 text-[#7D8AF3]"
              onClick={() => forgetPass()}
            >
              Forgot Password?
            </button>
          ) : null}

          <div className="mt-6 mb">
            <CustomButton
              onClick={() => login()}
              userLoading={state.btnLoading}
              value={"LOGIN"}
              color="primary"
            />
          </div>
          <div className="w-full h-auto flex flex-row items-center justify-center">
            <PrimaryText className="text-textColor2 text-sm leading-normal font-[Cambay]">
              Not a member?
            </PrimaryText>
            <button
              className="w-auto h-auto p-2 text-sm leading-normal font-[Cambay] hover:underline underline-offset-4 text-[#7D8AF3]"
              onClick={() => register()}
            >
              Register
            </button>
          </div>
        </Container>
      </Content>
    </Dialog>
  );
}

const Content = tw.div`
w-auto
h-auto 
sm:py-4
sm:px-6
sm2:p-[12px]
p-auto
md:overflow-x-hidden
sm:overflow-x-hidden
overflow-x-auto
overflow-y-auto
custom-scroll
`;
const Container = tw.div`
md:w-[450px]
sm:w-full
w-full
h-auto 
flex 
flex-col
`;
const Line = tw.div`
w-full
h-[1.1px] 
bg-[#C5C5C5] 
my-2
`;

export default LoginModal;
