import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Zoom } from "@mui/material";
import { PrimaryText } from "@components";

function CustomModal(props) {
  return (
    <Dialog
      open={props.openModal}
      onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <PrimaryText className="text-gray118 text-md  font-[Cambay]">
          {props.description}
        </PrimaryText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeModal} sx={{ color: "#000000" }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomModal;
