import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import tw from "tailwind-styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Zoom } from "@mui/material";
import rightView from "@assets/image/rightView.png";
import rightIcon from "@assets/image/rightIcon.png";
import { remove } from "@utils";
import { PrimaryText, TostMessage } from "@components";
import { getSelecetdPlayerData, getListingBookings } from "@store";
import "./teeTimeBox.css";

function TeeTimeBox(props) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activePlayer = useSelector((state) => state.courses.activePlayer);
  const addToCartData = useSelector((state) => state.bookings?.addToCartData);
  const listingBookingData = useSelector(
    (state) => state.bookings?.listingBookingData
  );

  const [state, setState] = useState({
    menuModal: null,
    availableDots: 0,
    dots: [1, 2, 3, 4],
    alertMessage: false,
    priceOfPlayer: null,
    checkoutLoder: false,
    players: activePlayer,
    addToCartLoder: false,
    alertMessageTxt: null,
  });

  useEffect(() => {
    if (listingBookingData?.data?.length) {
      let result = [];
      result = result.concat(
        listingBookingData?.data?.filter((e) => {
          return (
            e?.teetime === props?.teeTimeData?.time &&
            (e?.status === "BOOKED" || e?.status === "CREATED")
          );
        })
      );
      let totalPayers = result.reduce(
        (total, currentValue) => total + currentValue.players,
        0
      );

      setState((prev) => ({
        ...prev,
        availableDots: totalPayers,
      }));
    }
  }, [listingBookingData]);

  useEffect(() => {
    let localdata = JSON.parse(localStorage.getItem("LocalCartData"));
    if (localdata?.length && !addToCartData?.length) {
      dispatch(
        getSelecetdPlayerData({ localdata: localdata, localSave: true })
      );
    }
  }, [props, addToCartData]);

  const selectPlayer = (e, item) => {
    setState((prev) => ({ ...prev, menuModal: e?.currentTarget }));
  };

  const checkListingBooking = (price, type) => {
    let formDate = moment(props?.teeTimeData?.time)
      .subtract(1, "day")
      .format("DD-MM-YYYY");
    let toDate = moment(props?.teeTimeData?.time)
      .add(1, "day")
      .format("DD-MM-YYYY");

    var bookingsApiData = {
      fromDate: formDate,
      toDate: toDate,
    };
    dispatch(getListingBookings(bookingsApiData)).then(async (res) => {
      if (
        (await res?.payload?.data?.data?.data) &&
        (await res?.payload?.status) === 200
      ) {
        let listingData = await res?.payload?.data?.data?.data;
        let result = [];

        result = result.concat(
          listingData?.filter((e) => {
            return (
              e?.teetime === props?.teeTimeData?.time &&
              (e?.status === "BOOKED" || e?.status === "CREATED")
            );
          })
        );
        let totalPayers = result.reduce(
          (total, currentValue) => total + currentValue.players,
          0
        );

        if (
          result?.length &&
          (totalPayers >= 4 || 4 - totalPayers < price?.players)
        ) {
          setState((prev) => ({
            ...prev,
            alertMessage: true,
            alertMessageTxt:
              "We are sorry, this tee time is currently blocked by a other users shopping cart. please try again later. !",
            menuModal: null,
            addToCartLoder: false,
            checkoutLoder: false,
          }));
        } else {
          dispatch(getSelecetdPlayerData({ props, price, save: true }));
          setState((prev) => ({
            ...prev,
            menuModal: null,
            addToCartLoder: false,
            checkoutLoder: false,
            alertMessage: false,
            alertMessageTxt: null,
          }));
          if (type === "teeTime") {
            if (window.location.pathname === "/teeTimeBooking") {
              navigate("/teeTimeCheckOut");
            } else {
              navigate("/check-out");
              window.scrollTo(0, 0);
            }
          }
        }
      } else {
        dispatch(getSelecetdPlayerData({ props, price, save: true }));
        setState((prev) => ({
          ...prev,
          menuModal: null,
          addToCartLoder: false,
          checkoutLoder: false,
        }));
        if (type === "teeTime") {
          if (window.location.pathname === "/teeTimeBooking") {
            navigate("/teeTimeCheckOut");
          } else {
            navigate("/check-out");
            window.scrollTo(0, 0);
          }
        }
      }
    });
  };

  const addToCart = (item) => {
    const price = state.priceOfPlayer || item;
    if (props?.isSelect) {
      dispatch(getSelecetdPlayerData({ props, price, save: true }));
      setState((prev) => ({
        ...prev,
        menuModal: null,
      }));
    } else {
      setState((prev) => ({ ...prev, addToCartLoder: true }));
      checkListingBooking(price, "cart");
    }
  };

  const checkOutPay = (item) => {
    remove("CompetitonBookData");
    if (props?.isSelect) {
      setState((prev) => ({ ...prev, menuModal: null }));
      if (window.location.pathname === "/teeTimeBooking") {
        navigate("/teeTimeCheckOut");
      } else {
        navigate("/check-out");
        window.scrollTo(0, 0);
      }
    } else {
      const price = state.priceOfPlayer || item;
      setState((prev) => ({ ...prev, checkoutLoder: true }));
      checkListingBooking(price, "teeTime");
    }
  };

  const PlayerBtn = (item) => {
    const selectPlayerPrice = () => {
      setState((prev) => ({
        ...prev,
        players: item?.value?.quantity,
        priceOfPlayer: item?.value,
      }));
    };
    return (
      <button
        onClick={() => selectPlayerPrice()}
        className={`${roundBtn} ${
          state.players === item?.value?.quantity
            ? "bg-primary text-white255 hover:cursor-default"
            : "border text-textColor2 hover:opacity-70"
        }`}
      >
        {item?.value?.quantity}
      </button>
    );
  };

  const PriceView = (e) => {
    return (
      <span className={priceTxt}>
        {`${getSymbolFromCurrency(e?.data?.publicRate?.currency)} ${
          e?.data?.publicRate?.amount / activePlayer
        }`}
      </span>
    );
  };

  const btnStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "13px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  };

  return (
    <>
      {state.dots
        ?.slice(0, 4 - state.availableDots)
        ?.slice(0, props?.teeTimeData?.products[0]?.available)?.length > 0 &&
        props?.teeTimeData?.products[0]?.pricing?.map((item, index) => (
          <React.Fragment key={`teeTime${index}`}>
            {item?.quantity === activePlayer && (
              <>
                <Box
                  className={`${
                    state.menuModal
                      ? "cursor-pointer border-primary border-2 bg-white255a"
                      : ""
                  }`}
                >
                  <Button onClick={(e) => selectPlayer(e, item)} sx={btnStyle}>
                    <div className="flex flex-col items-center justify-center h-auto">
                      <PriceView data={item} />
                      <span className={timeTxt}>
                        {moment(props?.teeTimeData?.time).format("HH:mm")}
                      </span>
                    </div>

                    {props?.isSelect && (
                      <ImgView>
                        <img
                          src={rightView}
                          alt="img"
                          style={{
                            width: "44px",
                            height: "25px",
                            marginTop: "-1px",
                            marginLeft: "1px",
                            borderTopRightRadius: "10px",
                          }}
                        />
                        <img
                          src={rightIcon}
                          alt="img"
                          style={{
                            width: "10px",
                            height: "10px",
                            marginLeft: "25px",
                            marginTop: "-20px",
                          }}
                        />
                      </ImgView>
                    )}
                    <DoteView>
                      {state.dots
                        ?.slice(0, 4 - state.availableDots)
                        ?.slice(0, props?.teeTimeData?.products[0]?.available)
                        ?.map((d, index2) => (
                          <React.Fragment key={`teeTime${index2}`}>
                            <div className="w-[4px] h-[4px] bg-navy rounded-2xl mt-[3px]" />
                          </React.Fragment>
                        ))}
                    </DoteView>
                  </Button>
                </Box>
                <Menu
                  anchorEl={state.menuModal}
                  open={Boolean(state.menuModal)}
                  onClose={() =>
                    setState((prev) => ({ ...prev, menuModal: null }))
                  }
                  TransitionComponent={Zoom}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.32))",
                      borderRadius: "14px",
                      mt: 1,
                    },
                  }}
                  transformOrigin={{ horizontal: "center", vertical: "top" }}
                  anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                >
                  <div className="sm:w-[230px] sm2:w-[230px] w-[215px] h-auto mx-4">
                    <PlayerView>
                      <PrimaryText className={playerTxt}>
                        {t("players")}
                      </PrimaryText>
                      <div className="flex flex-row justify-center items-center gap-x-2">
                        {props?.teeTimeData?.products[0]?.pricing
                          ?.slice(0, 4 - state.availableDots)
                          ?.slice(0, props?.teeTimeData?.products[0]?.available)
                          ?.map((item2, index2) => (
                            <React.Fragment key={`price${index2}`}>
                              <PlayerBtn value={item2} />
                            </React.Fragment>
                          ))}
                      </div>
                    </PlayerView>
                    <Line />
                    <SelectView>
                      <button onClick={() => addToCart(item)} className={btn}>
                        {state.addToCartLoder ? (
                          <CircularProgress
                            sx={{ color: "#ffffff" }}
                            size={20}
                            thickness={5}
                          />
                        ) : (
                          <span className={txt}>
                            {" "}
                            {props?.isSelect
                              ? `${t("removeCart")}`
                              : `${t("addCart")}`}
                          </span>
                        )}
                      </button>
                      <button onClick={() => checkOutPay(item)} className={btn}>
                        {state.checkoutLoder ? (
                          <CircularProgress
                            sx={{ color: "#ffffff" }}
                            size={20}
                            thickness={5}
                          />
                        ) : (
                          <span className={txt}>
                            {" "}
                            {props?.isSelect
                              ? `${t("checkout")}`
                              : `${t("addCheckout")}`}
                          </span>
                        )}
                      </button>
                    </SelectView>
                  </div>
                </Menu>
              </>
            )}
          </React.Fragment>
        ))}
      {state.alertMessageTxt && (
        <TostMessage
          open={state.alertMessage}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              alertMessage: false,
              alertMessageTxt: null,
            }))
          }
          title={state.alertMessageTxt}
          type={"info"}
        />
      )}
    </>
  );
}

const Box = tw.div`
flex
flex-row
items-center
justify-around
hover:cursor-pointer
hover:border-primary
hover:border-2
mr-[10px]
mt-[10px]
bg-white255
hover:bg-white255a
md:h-[64px]
w-[85px]
h-[50px]
rounded-xl
border-[1px]
border-gray-800
`;
const publicpriceTxt = `
text-base
font-[Cambay] 
text-textColor2
leading-3
opacity-60
line-through
`;
const priceTxt = `
text-xl
font-medium
font-[Cambay] 
text-black
md:leading-7
leading-5
`;
const timeTxt = `
text-lg
font-medium
font-[Cambay] 
text-textColor2 
opacity-90
md:leading-4
leading-3
`;
const roundBtn = `
w-8 
h-8 
rounded-full 
flex 
items-center 
justify-center
border-[#828282]
`;
const ImgView = tw.div`
w-[39px] 
h-[20px] 
absolute 
top-0 
right-0 
rounded-tr-[10px]
`;
const DoteView = tw.div`
h-[70%] 
w-auto 
flex 
flex-col 
items-center 
justify-end 
overflow-hidden
`;
const PlayerView = tw.div`
w-full 
h-auto 
flex 
flex-row 
items-center 
justify-center
gap-x-[4px]
`;
const Line = tw.div`
w-full
h-[1.1px] 
bg-[#C5C5C5] 
mt-4
`;
const SelectView = tw.div`
w-full 
h-auto 
flex 
flex-col 
items-center 
justify-between 
mt-[12px]
mb-[5px]
gap-y-2
`;
const playerTxt = `
text-md
font-semibold 
font-[Cambay] 
text-center 
text-textColor2
`;
const btn = `
w-full 
h-auto 
rounded-full 
text-center 
px-[5px]
py-[5px] 

bg-primary
hover:bg-primary2
`;
const txt = `
font-medium 
text-sm 
font-[Cambay] 
text-center 
text-white255
uppercase
truncate
`;

export default TeeTimeBox;
