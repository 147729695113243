import React, { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import _ from "lodash";
import moment from "moment";
import tw from "tailwind-styled-components";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PrimaryText, TostMessage } from "@components";
import {
  isModal,
  payLoader,
  getBookingData,
  createPaymentIntent,
  paymentUpdateBooking,
  getSelecetdPlayerData,
} from "@store";
import SuccessModal from "./successModal";
import "../../containerStyle.css";

function PaymentDetails(props) {
  const { onPayClick, inputComplete, userDetails } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation("common");
  const addToCartData = useSelector((state) => state.bookings?.addToCartData);
  const facilityData = useSelector((state) => state.courses?.clubCoursData);
  const clubData = useSelector((state) => state.clubs.clubsData);
  const clubPhone = clubData?.data ? clubData?.data[0]?.attributes?.phone : "";
  const prices = addToCartData?.length
    ? addToCartData?.map((a) => a?.price?.publicRate?.amount)
    : "";
  const totalPrice = addToCartData?.length
    ? prices.reduce((acc, curr) => acc + curr)
    : "";
  const teeTimeCurrency = addToCartData?.length
    ? addToCartData[0]?.price?.price?.currency
    : "EUR";

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      payError: false,
      payDetail: null,
      successModal: false,
      paymentCancellation: null,
      paymentCancellationMessage: "",
      bookingApiData: null,
      alertMessage: false,
      alertMessageTxt: null,
    }
  );

  useEffect(() => {
    if (userDetails?.userData?.user) {
      setState({
        bookingApiData: {
          facilityId: facilityData?.id,
          contactPhone: userDetails?.telePhone,
          bookings: userDetails?.bookingList,
          contactEmail: userDetails?.email,
          clubPhone: clubPhone,
          amountPaid: totalPrice,
          contactFirstName: userDetails?.firstName,
          contactLastName: userDetails?.lastName,
        },
      });
    } else {
      setState({
        bookingApiData: {
          facilityId: facilityData?.id,
          bookings: userDetails?.bookingList,
          contactPhone: userDetails?.telePhone,
          contactEmail: userDetails?.email,
          clubPhone: clubPhone,
          amountPaid: totalPrice,
          contactFirstName: userDetails?.firstName,
          contactLastName: userDetails?.lastName,
        },
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (inputComplete) {
      if (state.payDetail === null || !state.payDetail?.complete) {
        setState({ payError: true });
      } else {
        setState({ payError: false });
        handleSubmit();
      }
    }
  }, [inputComplete]);

  useEffect(() => {
    if (state.successModal) {
      dispatch(isModal(true));
    } else {
      dispatch(isModal(false));
    }
  }, [state.successModal]);

  const closeSuccessModal = () => {
    setState({
      successModal: false,
      paymentCancellationMessage: "",
    });
    localStorage.removeItem("AddToCartsData");
    dispatch(payLoader(false));
    dispatch(getSelecetdPlayerData({ save: false }));
    if (window.location.pathname === "/teeTimeCheckOut") {
      navigate("/teeTimeBooking");
    } else {
      navigate("/");
      window.scrollTo(0, 0);
    }
  };

  const change = (e) => {
    setState({ payError: false, payDetail: e });
    onPayClick();
  };

  const handleSubmit = async () => {
    onPayClick();
    dispatch(payLoader(true));

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement, CardNumberElement);
    const totalAmount = userDetails.multipleBookingPayment.reduce(
      (acc, booking) => {
        return acc + booking.amount;
      },
      0
    );
    const paymentApiData = {
      name: userDetails?.firstName + " " + userDetails?.lastName,
      email: userDetails?.email,
      contactPhone: userDetails?.telePhone,
      currency: teeTimeCurrency,
      bookingPayment: userDetails?.multipleBookingPayment,
      facilityId: facilityData?.id,
      street: userDetails?.street,
      companyName: userDetails?.companyName,
      city: userDetails?.town,
      zipCode: userDetails?.zipCode,
      amount: totalAmount,
      country: "Portugal",
      description: userDetails?.specialRequests
        ? userDetails?.specialRequests
        : "Booking Tee-Time",
    };
    createPayment(paymentApiData, cardElement);
  };

  const createPayment = async (apiData, cardElement) => {
    await dispatch(createPaymentIntent(apiData)).then(async (res) => {
      let paymentRes = res?.payload?.data?.data?.paymentsDetail;
      if (res?.payload?.data?.data?.payment?.status === "intent-generated") {
        let securePayment = await stripe.confirmCardPayment(
          paymentRes?.clientSecret,
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
        if (securePayment?.paymentIntent?.status === "succeeded") {
          bookingTees(paymentRes);
          dispatch(payLoader(true));
        } else {
          setState({
            alertMessage: true,
            alertMessageTxt:
              securePayment?.error?.message ||
              "A generic error occurred on the confirm Card Payment !",
          });
          dispatch(payLoader(false));
        }
      } else if (
        res?.payload?.response?.data?.data?.payment?.message &&
        res?.payload?.response?.data?.data?.payment?.status === "failed"
      ) {
        setState({
          alertMessage: true,
          alertMessageTxt: `${res?.payload?.response?.data?.data?.payment?.message} Please try again with another card`,
        });
        dispatch(payLoader(false));
      } else {
        dispatch(payLoader(false));
        res?.payload?.response?.data?.message
          ? setState({
              alertMessage: true,
              alertMessageTxt: res?.payload?.response?.data?.message,
            })
          : res?.payload?.response?.data?.error?.message
          ? setState({
              alertMessage: true,
              alertMessageTxt: res?.payload?.response?.data?.error?.message,
            })
          : setState({
              alertMessage: true,
              alertMessageTxt:
                "A generic error occurred on the Payment Intent !",
            });
      }
    });
  };

  const bookingTees = async (paymentRes) => {
    let apiSlug = {
      ...state.bookingApiData,
      paymentIntent: paymentRes?.paymentIntentId,
    };

    await dispatch(getBookingData(apiSlug)).then((res) => {
      if (
        res?.payload?.status === 200 &&
        res?.payload?.data &&
        res?.payload?.data?.data?.groupId
      ) {
        let bookingRes = res?.payload?.data?.data;
        const newFormattedBookingData = bookingRes?.bookingIds?.map(
          ({ teetime, courseId, ...rest }) => ({
            ...rest,
            teeTime: moment(teetime).format("YYYY-MM-DDTHH:mm"),
          })
        );
        let data = {
          paymentId: paymentRes?.paymentId,
          paymentIntentId: paymentRes?.paymentIntentId,
          paymentLogs: newFormattedBookingData,
          bulkBookingId: bookingRes?.groupId,
        };
        dispatch(paymentUpdateBooking(data));
        dispatch(payLoader(false));
        setState({ successModal: true });
      } else {
        let data = {
          paymentId: paymentRes?.paymentId,
          paymentIntentId: paymentRes?.paymentIntentId,
          status: "failed",
          paymentCancelReason: "Payment got canceled due to booking failed",
        };
        dispatch(paymentUpdateBooking(data));
        dispatch(payLoader(false));
        if (
          res?.payload?.response?.status === 400 &&
          res?.payload?.response?.data?.error?.message
        ) {
          payLoader(false);
          setState({
            alertMessage: true,
            alertMessageTxt:
              "This tee time is already booked by some one please try again with different tee time !",
          });
        } else if (res?.payload?.response?.data?.message) {
          setState({
            alertMessage: true,
            alertMessageTxt: res?.payload?.response?.data?.message,
          });
        } else {
          setState({
            alertMessage: true,
            alertMessageTxt: "A generic error occurred on the Booking time !",
          });
        }
      }
    });
  };

  return (
    <>
      <div className="mt-8 mb-4">
        <PrimaryText className="text-textColor text-xl font-medium opacity-80">
          {t("tournamentCheckout.paymentMethod")}
        </PrimaryText>
      </div>
      <Component>
        <div className="w-full h-auto">
          <CardElement
            onChange={(e) => change(e)}
            className={`${paymentImput}`}
          />

          <Collapse in={state.payError}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setState({ payError: false });
                    onPayClick();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              missing card details for payment — <strong>check it out!</strong>
            </Alert>
          </Collapse>
          <SuccessModal
            openModal={state.successModal}
            closeModal={closeSuccessModal}
            paymentCancellation={state.paymentCancellation}
            paymentCancellationMessage={state.paymentCancellationMessage}
          />
          {state.alertMessageTxt && (
            <TostMessage
              open={state.alertMessage}
              onClose={() =>
                setState({ alertMessage: false, alertMessageTxt: null })
              }
              title={state.alertMessageTxt}
              type={"info"}
            />
          )}
        </div>
      </Component>
    </>
  );
}

const Component = tw.div`
w-full
h-auto 
flex
flex-col
items-center
justify-center
`;
const paymentImput = `
w-full 
h-auto 
py-[15px]
px-4 
bg-gray-200
text-black21 
rounded-lg
focus:outline-none 
border-[#ffffff]
shadow-[0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)]
focus:border-[black]
placeholder:text-[#757680]
`;

export default PaymentDetails;
