import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import tw from "tailwind-styled-components";
import { PrimaryText } from "@components";
import { getSelecetdPlayerData, getCountDown } from "@store";
import TimeOutModal from "./timeOutModal.js";

function Timer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const isModals = useSelector((state) => state.header.isModal);
  const apiTimer = useSelector((state) => state.setting.time);
  const timer = apiTimer?.data?.data?.attributes?.countDown;

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      showTimer: false,
      timeOutModal: false,
    }
  );
  const [countDownTime, setCountDownTime] = useState(180);

  // useEffect(() => {
  //   dispatch(getCountDown());
  // }, []);

  useEffect(() => {
    if (timer) {
      setCountDownTime(timer * 60);
    }
  }, [timer]);

  var interval = null;
  useEffect(() => {
    interval = setInterval(() => {
      if (!isModals) {
        setCountDownTime((countDownTime) => countDownTime - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isModals, countDownTime]);

  if (countDownTime === 0) {
    setTimeout(() => {
      clearInterval(interval);
    }, 1000);
    if (state.showTimer) {
      setState({ showTimer: false, timeOutModal: true });
    }
  } else {
    if (!state.showTimer) {
      setState({ showTimer: true, timeOutModal: false });
    }
  }
  const minutes = Math.floor(countDownTime / 60);
  const seconds = countDownTime - minutes * 60;

  const closeModal = () => {
    dispatch(getSelecetdPlayerData({ save: false }));
    setState({ timeOutModal: false });
    navigate(-1);
  };

  const reStartModal = () => {
    setCountDownTime(timer * 60);
    setState({ timeOutModal: false });
  };

  return (
    <>
      {state.showTimer && timer && (
        <TimerView>
          <PrimaryText className={timerTxt}>
            {`${t("checkOut.timer.you")} ${
              minutes ? minutes + `${t("checkOut.timer.min")}` : ""
            } ${seconds} ${t("checkOut.timer.sec")}`}
          </PrimaryText>
        </TimerView>
      )}
      <TimeOutModal
        openModal={state.timeOutModal}
        closeModal={closeModal}
        reStartModal={reStartModal}
      />
    </>
  );
}

const TimerView = tw.div`
w-full 
max-w-[1500px]
h-auto 
mb-[10px] 
flex 
justify-center 
items-center 
p-2 
z-30
`;
const timerTxt = `
text-red255 
text-center 
font-semibold 
font-[Cambay]
`;

export default Timer;
