import * as types from "./types";
import _ from "lodash";

const initialState = {
  bookingData: [],
  cancelBooking: [],
  listingBookingData: [],
  addToCartData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SELECTED_PLAYER: {
      if (action?.payload?.localSave) {
        return {
          ...state,
          addToCartData: action?.payload?.localdata,
        };
      } else {
        if (action?.payload?.save) {
          if (
            state.addToCartData.find(
              (e) =>
                e?.teeTime?.teeTimeData?.id ===
                  action?.payload?.props?.teeTimeData?.id &&
                e?.teeTime?.teeTimeData?.course ===
                  action?.payload?.props?.teeTimeData?.course &&
                e?.teeTime?.teeTimeData?.time ===
                  action?.payload?.props?.teeTimeData?.time
            )
          ) {
            const arr = _.filter(state.addToCartData, function (e) {
              return (
                e?.teeTime?.teeTimeData?.id !==
                  action?.payload?.props?.teeTimeData?.id ||
                e?.teeTime?.teeTimeData?.course !==
                  action?.payload?.props?.teeTimeData?.course ||
                e?.teeTime?.teeTimeData?.time !==
                  action?.payload?.props?.teeTimeData?.time
              );
            });
            localStorage.setItem("LocalCartData", JSON.stringify(arr));
            return {
              ...state,
              addToCartData: arr,
            };
          } else {
            localStorage.setItem(
              "LocalCartData",
              JSON.stringify([
                ...state.addToCartData,
                {
                  teeTime: action?.payload?.props,
                  price: action?.payload?.price,
                },
              ])
            );
            return {
              ...state,
              addToCartData: [
                ...state.addToCartData,
                {
                  teeTime: action?.payload?.props,
                  price: action?.payload?.price,
                },
              ],
            };
          }
        } else {
          localStorage.setItem("LocalCartData", JSON.stringify([]));
          return {
            ...state,
            addToCartData: [],
          };
        }
      }
    }
    case types.GET_BOOKING_DATA: {
      return {
        ...state,
        bookingData: action?.payload,
      };
    }
    case types.CANCEL_BOOKING: {
      return {
        ...state,
        cancelBooking: action?.payload,
      };
    }
    case types.GET_LISTING_BOOKING_DATA: {
      return {
        ...state,
        listingBookingData: action?.payload?.data?.data,
      };
    }
    default:
      return state;
  }
};

export default reducer;
